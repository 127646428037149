import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Input, InputGroup, InputGroupText } from 'reactstrap';
import { FilterContext } from 'contexts/FilterContext';
import { AuthContext } from 'contexts/AuthContext';
import { login } from 'utils/api/authAPI';
import { SpinnerButton } from 'common/SpinnerButton';
import Logo from 'images/logo.png';
import './style.scss';
import { MicrosoftLogin } from 'components/MicrosoftLogin';

export const SignIn = () => {
    const navigate = useNavigate();
    const { signIn } = useContext(AuthContext);
    const { resetAllFilters } = useContext(FilterContext);

    const [err, setErr] = useState(false);

    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        resetAllFilters();
    }, []);

    const onSubmit = async () => {
        setErr(false);
        setSubmitting(true);
        try {
            const res = await login(email, password);

            if (res?.area === 'admin') {
                signIn(res.token);
                navigate('/account');
                return;
            }

            setErr(true);
            setSubmitting(false);
        } catch (err) {
            setErr(true);
            setSubmitting(false);
        }
    };

    return (
        <div className="auth-block">
            <div className="auth-block__inner">
                <div className="animated fadeIn">
                    <Row className="justify-content-center mt-5" style={{ marginBottom: '.5rem' }}>
                        <Col md={9}>
                            <div className="auth-block__image-container">
                                <a href="/">
                                    <img src={Logo} alt="Ozmosys" className="auth-block__image img-fluid" />
                                </a>
                            </div>
                            <div className="text-center">
                                <h2>Sign In</h2>
                                <p className="mb-4 mt-3 text-muted">Welcome back! Please sign in to continue</p>
                            </div>

                            {err &&
                                <div className="error-block mb-3">
                                    <p><b>Access Denied</b></p>
                                    <p>You are not authorized to access this application.
                                        Please contact <a href="mailto:support@ozmosys.com?subject=Access Denied Help"
                                            target="_blank">Ozmosys</a>, if you believe this is an error.</p>
                                </div>
                            }

                            {process.env.REACT_APP_MICROSOFT_CLIENT_ID &&
                                <MicrosoftLogin err={err} setErr={setErr} />
                            }

                            <InputGroup>
                                <InputGroupText className="input-addon">@</InputGroupText>
                                <Input
                                    placeholder="Email"
                                    name="email"
                                    value={email}
                                    type="email"
                                    autoComplete="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </InputGroup>

                            <InputGroup>
                                <InputGroupText className="input-addon">
                                    <i className="fa fa-lock" />
                                </InputGroupText>
                                <Input
                                    placeholder="Password"
                                    name="password"
                                    value={password}
                                    autoComplete="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                />
                            </InputGroup>

                            <Row>
                                <Col xs="12" className="text-left">
                                    <SpinnerButton
                                        color="primary"
                                        block
                                        disabled={email.length < 1 || password.length < 1}
                                        onClick={onSubmit}
                                        submitting={submitting}
                                        title="Login"
                                    />

                                    <div className="mt-3">
                                        <Link className="nav-link" to="/forgot-password">
                                            Forgot password?
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/*<Row>
                        <Col className="text-center mt-2">
                            <span className="align-middle text-muted">Don't have an account?</span>
                            <Link to="/signup">
                                <Button type="button" color="link">
                                    Sign up
                                </Button>
                            </Link>
                        </Col>
                    </Row>*/}
                </div>
            </div>
        </div>
    );
};
